<template>
	<div>
		<v-btn
			:loading="pageLoading || uploading"
			class="white--text avatar-change-btn"
			color="blue darken-4"
			icon
			large
			@click="changeImage"
			v-if="canChange"
			:disabled="pageLoading || uploading"
			style="z-index: 1"
		>
			<v-icon large>{{ value ? "mdi-camera-retake" : "mdi-camera-plus" }}</v-icon>
		</v-btn>
		<v-file-input
			:disabled="uploading"
			@change="updateFile($event)"
			class="d-none"
			ref="avatar-input"
			accept="image/png, image/jpeg"
		></v-file-input>
		<v-avatar size="150">
			<v-img height="100%" :src="getImagePath()" :lazy-src="getImagePath()"> </v-img>
		</v-avatar>
		<!-- <div class="ml-7 mt-3 primary--text cursor-pointer">Upload Image</div> -->
	</div>
</template>

<script>
import { POST } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import objectPath from "object-path";
export default {
	name: "v-avatar-update",
	props: {
		pageLoading: {
			type: Boolean,
			default: false,
		},
		canChange: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			profile: null,
			uploading: false,
		};
	},
	methods: {
		getImagePath() {
			if (this.value) {
				return this.value;
			}
			return process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png";
		},
		changeImage() {
			const avatar_ref = this.$refs["avatar-input"];
			if (avatar_ref) {
				avatar_ref.$refs["input"].click();
			}
		},
		/* updateFile(file) {
			this.uploading = true;
			const formData = new FormData();
			formData.append("files[0]", file);
			UploadFiles(formData)
				.then((response) => {
					const profile = head(response);
					if (profile.url) {
						this.$emit("input", profile.url);
					}
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.uploading = false;
				});
		}, */
		updateFile(e) {
			let formData = new FormData();
			formData.append("files", e);
			this.$store
				.dispatch(POST, { url: `upload`, data: formData })
				.then(({ data }) => {
					const profile = objectPath.get(data, "data.0");
					if (profile && profile.url) {
						this.$emit("input", profile.url);
					}
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: `Success ! Image ${this.value ? "updated" : "uploaded"} successfully.`,
						},
					]);
					// this.getSingleUser();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
};
</script>
